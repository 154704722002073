// export default ActionContext
import React, { createContext } from "react";
import { useState } from "react";
export const ActionContext = createContext();

export const ActionProvider = ({ children }) => {
  // Date and Time String
  const [dateTimeModal, setDateTimeModal] = useState("");
  // for update vehicle number
  const [vehicleNumberBookingId, setVehicleNumberBookingId] = useState("");
  const [isVehicleNumber, setIsVehicleNumber] = useState(false);
  const closeVehicleNumber = () => setIsVehicleNumber(false);
  const toggleVehicleNumber = () => setIsVehicleNumber(!isVehicleNumber);
  // toll and fuel modal
  const [tollBookingId, setTollBookingId] = useState("");
  const [isTollAndFuel, setIsTollAndFuel] = useState(false);
  const closeTollAndFuel = () => setIsTollAndFuel(false);
  const toggleTollAndFuel = () => setIsTollAndFuel(!isTollAndFuel);
  // cancle booking
  const [isCancelBooking, setIsCancelBooking] = useState("");
  const [isCancelModal, setIsCancelModal] = useState(false);
  const closeCancelModal = () => setIsCancelModal(false);
  const toggleCancelModal = () => setIsCancelModal(!isCancelModal);
  //Reschedule
  const [rescheduleBookingId, setRescheduleBookingId] = useState("");
  const [isRescheduleModal, setIsRescheduleModal] = useState(false);
  const closeRescheduleModal = () => setIsRescheduleModal(false);
  const toggleRescheduleModal = () => setIsRescheduleModal(!isRescheduleModal);
  // Checklist
  const [isChecklistModal, setIsChecklistModal] = useState(false);
  const [checklistData, setCheckListData] = useState([]);
  const closeChecklistModal = () => setIsChecklistModal(false);
  const toggleChecklistModal = () => setIsChecklistModal(!isChecklistModal);
  const [checkListBookingId, setCheckListBookingId] = useState("");
  // TrackRoute
  const [isTrackRouteModal, setIsTrackRouteModal] = useState(false);
  const closeTrackRouteModal = () => setIsTrackRouteModal(false);
  const toggleTrackRouteModal = () => setIsTrackRouteModal(!isTrackRouteModal);
  const [trackRouteBookingId, setTrackRouteBookingId] = useState("");
  // ChangeDrop
  const [isChangeDropModal, setIsChangeDropModal] = useState(false);
  const closeChangeDropModal = () => setIsChangeDropModal(false);
  const toggleChangeDropModal = () => setIsChangeDropModal(!isChangeDropModal);
  const [ChangeDropBookingId, setChangeDropBookingId] = useState("");

  //  createReturn
  const [createReturnBooking, setCreateReturnBooking] = useState("");
  // const [createReturnAPIData, setCreateReturnAPIData] = useState({});
  const [iscreateReturnModal, setIscreateReturnModal] = useState(false);

  // validate

  const validate = (e) => {
    let key;
    var theEvent = e || window.event;
    if (theEvent.type === "paste") {
      key = e.clipboardData.getData("text/plain");
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  return (
    <ActionContext.Provider
      value={{
        // date time modal value
        dateTimeModal,
        setDateTimeModal,
        // vehicle numebr
        vehicleNumberBookingId,
        setVehicleNumberBookingId,
        closeVehicleNumber,
        toggleVehicleNumber,
        isVehicleNumber,
        // toll and fuel modal
        closeTollAndFuel,
        toggleTollAndFuel,
        isTollAndFuel,
        tollBookingId,
        setTollBookingId,
        //cancel modal
        isCancelBooking,
        setIsCancelBooking,
        isCancelModal,
        closeCancelModal,
        toggleCancelModal,
        //Reschedule
        rescheduleBookingId,
        setRescheduleBookingId,
        isRescheduleModal,
        closeRescheduleModal,
        toggleRescheduleModal,
        //Checklist
        isChecklistModal,
        closeChecklistModal,
        toggleChecklistModal,
        checkListBookingId,
        setCheckListBookingId,
        checklistData,
        setCheckListData,
        //track route
        trackRouteBookingId,
        setTrackRouteBookingId,
        isTrackRouteModal,
        closeTrackRouteModal,
        toggleTrackRouteModal,
        // change drop
        isChangeDropModal,
        closeChangeDropModal,
        toggleChangeDropModal,
        ChangeDropBookingId,
        setChangeDropBookingId,

        //createReturn
        createReturnBooking,
        setCreateReturnBooking,
        iscreateReturnModal,
        setIscreateReturnModal,
        // createReturnAPIData,
        // setCreateReturnAPIData,

        // validate
        validate,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};
