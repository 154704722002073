import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Windmill } from "@windmill/react-ui";
import "../src/assets/css/custom.css";
import "../src/assets/css/tailwind.css";
import "../src/assets/css/tailwind.output.css";
import "mapbox-gl/dist/mapbox-gl.css";
import ThemeSuspense from "../src/components/theme/ThemeSuspense";
import myTheme from "../src/assets/theme/myTheme";
import { SidebarProvider } from "../src/context/SidebarContext";
import { AdminProvider } from "../src/context/AdminContext";
import { CreateBookingProvider } from "../src/context/CreateBookingContext";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider, LightTheme } from "baseui";
import { ActionProvider } from "../src/context/ActionContext";
const engine = new Styletron();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AdminProvider>
    <ActionProvider>
      <SidebarProvider>
        <CreateBookingProvider>
          <StyletronProvider value={engine}>
            <BaseProvider theme={LightTheme}>
              <Suspense fallback={<ThemeSuspense />}>
                <Windmill usePreferences theme={myTheme}>
                  <App />
                </Windmill>
              </Suspense>
            </BaseProvider>
          </StyletronProvider>
        </CreateBookingProvider>
      </SidebarProvider>
    </ActionProvider>
  </AdminProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
