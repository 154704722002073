// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";
import { notifyError } from "../utils/toast";
const firebaseConfig = {
  apiKey: "AIzaSyCv91GPBFrUBYRx8LeTzm0EH_G2dTt7QY4",
  authDomain: "driveu-b2b.firebaseapp.com",
  projectId: "driveu-b2b",
  storageBucket: "driveu-b2b.appspot.com",
  messagingSenderId: "1052253235316",
  appId: "1:1052253235316:web:762be9432252ab14954921",
  measurementId: "G-S2H50WW69E",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
function handleSendFCMToken(currentToken) {
  let adminInfo;
  if (Cookies.get("adminInfo")) {
    adminInfo = JSON.parse(Cookies.get("adminInfo"));
  }

  axios({
    method: "POST",
    baseURL: BASE_URL_HULK,
    url: "update-fcm-token/",
    data: {
      fcm_token: currentToken,
    },
    headers: {
      Authorization: `Bearer ${adminInfo.access}`,
      "Content-Type": `multipart/form-data`,
    },
    withCredentials: false,
  })
    .then((res) => {
      if (res.data.status === "success") {
        // console.log("then");
        // console.log(res);
        // notifySuccess(res.data.message);
      } else if (res.data.status === "error") {
        notifyError(res.data.message);
      }
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   updateToken(handleFCMToken);
      // }
      // notifyError(error.message);
      console.log(error);
    });
}

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      Cookies.set("notificationToken", currentToken);

      if (currentToken) {
        if (window.Notification.permission === "granted") {
          handleSendFCMToken(currentToken);
        } else if (window.Notification.permission === "denied") {
          console.log("denied");
        }
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI

        notifyError(
          "No registration token available. Request permission to generate one."
        );
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      // notifyError("You have Blocked notifications.");
      console.log(err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
