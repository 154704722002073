import axios from "axios";
import Cookies from "js-cookie";
import React, { useState, createContext } from "react";
import { useContext } from "react";
import { AdminContext } from "./AdminContext";
// create context

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [agentsListData, setAgentsListData] = useState([] || {});
  const [pdsAccountTransList, setPdsAccountTransList] = useState([] || {});
  const { updateToken } = useContext(AdminContext);

  //   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ispickAddressModalOpen, setIsPickAddressModalOpen] = useState(false);
  const [isdropAddressModalOpen, setIsDropAddressModalOpen] = useState(false);
  const [isCityModalOpen, setCityModalOpen] = useState(false);
  const [isAgentModalOpen, setAgentModalOpen] = useState(false);
  const [isAgentModalDeleteOpen, setAgentModalDeleteOpen] = useState(false);
  const [isAgentModalAddOpen, setAgentModalAddOpen] = useState(false);

  const [showNotiFicationCard, setShowNotiFicationCard] = useState(false);
  const [showB2BLoader, setShowB2BLoader] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationLoadingDrop, setLocationLoadingDrop] = useState(false);
  const [locationLoadingCity, setLocationLoadingCity] = useState(false);
  const [localAgentData, setLocalAgentData] = useState([] || {});
  const [userAction, setUserAction] = useState("");
  //   const [isBulkDrawerOpen, setIsBulkDrawerOpen] = useState(false);
  //   const [isUpdate, setIsUpdate] = useState(false);

  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  //   const closeDrawer = () => setIsDrawerOpen(false);
  //   const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const closeAgentModalAdd = () => setAgentModalAddOpen(false);
  const toggleAgentModalAdd = () => setAgentModalAddOpen(!isAgentModalAddOpen);

  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const closePickModal = () => setIsPickAddressModalOpen(false);
  const togglePickModal = () => {
    setIsPickAddressModalOpen(!ispickAddressModalOpen);
    setLocationLoading(false);
  };

  const closeDropModal = () => setIsDropAddressModalOpen(false);
  const toggleDropModal = () => {
    setIsDropAddressModalOpen(!isdropAddressModalOpen);
    setLocationLoadingDrop(false);
  };

  const closeCityModal = () => setCityModalOpen(false);
  const toggleCityModal = () => {
    setCityModalOpen(!isCityModalOpen);
    setLocationLoadingCity(false);
  };

  const closeAgentModal = () => setAgentModalOpen(false);
  const toggleAgentModal = () => setAgentModalOpen(!isAgentModalOpen);

  const closeAgentModalDelete = () => setAgentModalDeleteOpen(false);
  const toggleAgentModalDelete = () =>
    setAgentModalDeleteOpen(!isAgentModalDeleteOpen);

  //   const closeBulkDrawer = () => setIsBulkDrawerOpen(false);
  //   const toggleBulkDrawer = () => setIsBulkDrawerOpen(!isBulkDrawerOpen);
  const handleChangePage = (p) => {
    setCurrentPage(p);
  };
  // hemendra's state
  const [pdsAccountData, setPdsAccountData] = useState([] || {});
  const [trackPastData, setTrackPastData] = useState([] || {});
  const [loading, setLoading] = useState(true);
  const [loadingTrackData, setLoadingTrackData] = useState(false);
  const [trackStatus, setTrackStatus] = useState(0);
  const [trackPage, setTrackPage] = useState(1);

  const [globalSearch, setGlobalSearch] = useState("booking_id");
  const [globalParams, setGlobalParams] = useState("");

  // khud ke bnaye hue state
  // const [addressValue, setAddressValue] = useState("");
  // const [addressValueCount, setAddressValueCount] = useState(0);
  const [bookinginfo, setBookingInfo] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [savedLocation, setSavedLocation] = useState([]);
  const [addMoneyData, setAddMoneyData] = useState({
    type: "organisation",
    currency: "INR",
    description: "PDS Wallet Recharge",
  });
  //city Address
  const [cityLat, setCityLat] = useState(null);
  const [cityLng, setCityLng] = useState(null);
  const [cityAutoCompleteData, setcityAutocompleteData] = useState(null);
  const [cityAddress, setcityAddress] = useState("");

  // pickup Latitude and Logitutde
  const [pickLat, setPickLat] = useState(null);
  const [pickLng, setPickLng] = useState(null);
  const [pickupAutoCompleteData, setPickupAutocompleteData] = useState(null);
  const [pickAddress, setPickAddress] = useState("");

  // drop latitude and longitude
  const [dropLat, setDropLat] = useState(null);
  const [dropLng, setDropLng] = useState(null);
  const [dropupAutoCompleteData, setDropupAutocompleteData] = useState(null);
  const [dropAddress, setDropAddress] = useState("");

  // for getting address from the coordinate

  const getReverseGeocodingData = (modal, lat, lng) => {
    // dropmodal pickmodal
    var latlng = new window.google.maps.LatLng(lat, lng);
    var geocoder = new window.google.maps.Geocoder();
    if (modal === "pickmodal") {
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
        }
        if (status === window.google.maps.GeocoderStatus.OK) {
          var address = results[0].formatted_address;
          // console.log(address, "line 62 in SideBarContext");
          setPickAddress(address);
          closePickModal();
          setPickLat(lat);
          setPickLng(lng);
        }
      });
    } else if (modal === "dropmodal") {
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
        }
        if (status === window.google.maps.GeocoderStatus.OK) {
          var address = results[0].formatted_address;
          setDropAddress(address);
          closeDropModal();
          setDropLat(lat);
          setDropLng(lng);
        }
      });
    } else if (modal === "cityModal") {
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
        }
        if (status === window.google.maps.GeocoderStatus.OK) {
          var address = results[0].formatted_address;
          setcityAddress(address);
          closeCityModal();
          setCityLat(lat);
          setCityLng(lng);
        }
      });
    }
  };

  // for getting coordinate
  const handleCorrdinate = (data) => () => {
    if (data === "pickup") {
      setLocationLoading(true);
      if (pickLat === null && pickLng === null) {
        navigator.geolocation.getCurrentPosition((position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          setPickLat(lat);
          setPickLng(lng);

          togglePickModal();
        });
      } else {
        togglePickModal();
      }
    } else if (data === "drop") {
      // dropLat,dropLng, setDropLat,setDropLng,
      setLocationLoadingDrop(true);
      if (dropLat === null && dropLng === null) {
        navigator.geolocation.getCurrentPosition((position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          setDropLat(lat);
          setDropLng(lng);
          toggleDropModal();
        });
      } else {
        toggleDropModal();
      }
    } else if (data === "city") {
      // dropLat,dropLng, setDropLat,setDropLng,
      setLocationLoadingCity(true);
      if (cityLat === null && cityLng === null) {
        navigator.geolocation.getCurrentPosition((position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          setCityLat(lat);
          setCityLng(lng);
          toggleCityModal();
        });
      } else {
        toggleCityModal();
      }
    }
  };

  const handleUpdateTrackPastData = () => {
    let adminInfo;
    if (Cookies.get("adminInfo")) {
      adminInfo = JSON.parse(Cookies.get("adminInfo"));
    }
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url: `${BASE_URL_HULK}current-bookings/`,
      params: {
        page_size: 10,
        page: trackPage,
        status: trackStatus,
      },
      headers: {
        Authorization: `Bearer ${adminInfo.access}`,
        "Content-Type": `application/json`,
      },
      withCredentials: false,
    })
      .then((res) => {
        if (res) {
          setTrackPastData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          updateToken(handleUpdateTrackPastData);
        }
      });
  };
  // end of the coordinate and getting the address
  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        currentPage,
        // isDrawerOpen,
        // toggleDrawer,
        // closeDrawer,
        // setIsDrawerOpen,
        isModalOpen,
        toggleModal,
        closeModal,
        handleChangePage,

        // hemendra
        pdsAccountData,
        setPdsAccountData,
        loading,
        setLoading,

        // isUpdate,
        // setIsUpdate,
        // closeBulkDrawer,
        // isBulkDrawerOpen,
        // toggleBulkDrawer,

        //city Address
        cityLat,
        setCityLat,
        cityLng,
        setCityLng,
        cityAutoCompleteData,
        setcityAutocompleteData,
        cityAddress,
        setcityAddress,
        //city toggle
        isCityModalOpen,
        closeCityModal,
        toggleCityModal,
        //

        bookinginfo,
        setBookingInfo,
        commonData,
        setCommonData,
        savedLocation,
        setSavedLocation,
        addMoneyData,
        setAddMoneyData,

        // pickup address
        pickLat,
        pickLng,
        pickAddress,
        setPickAddress,
        setPickLat,
        setPickLng,
        pickupAutoCompleteData,
        setPickupAutocompleteData,
        // pickup toogle
        ispickAddressModalOpen,
        closePickModal,
        togglePickModal,
        // drop Address
        dropLat,
        dropLng,
        dropAddress,
        dropupAutoCompleteData,
        setDropAddress,
        setDropLat,
        setDropLng,
        setDropupAutocompleteData,
        // drop toogle
        isdropAddressModalOpen,
        closeDropModal,
        toggleDropModal,
        //common function
        getReverseGeocodingData,
        handleCorrdinate,

        trackStatus,
        setTrackStatus,
        trackPage,
        setTrackPage,

        trackPastData,
        setTrackPastData,
        // agentslist
        closeAgentModal,
        toggleAgentModal,
        isAgentModalOpen,
        agentsListData,
        setAgentsListData,

        closeAgentModalDelete,
        toggleAgentModalDelete,
        isAgentModalDeleteOpen,

        closeAgentModalAdd,
        toggleAgentModalAdd,
        isAgentModalAddOpen,

        globalSearch,
        setGlobalSearch,
        globalParams,
        setGlobalParams,

        loadingTrackData,
        setLoadingTrackData,

        showNotiFicationCard,
        setShowNotiFicationCard,
        pdsAccountTransList,
        setPdsAccountTransList,
        handleUpdateTrackPastData,
        showB2BLoader,
        setShowB2BLoader,
        locationLoading,
        setLocationLoading,
        locationLoadingDrop,
        setLocationLoadingDrop,
        locationLoadingCity,
        setLocationLoadingCity,
        localAgentData,
        setLocalAgentData,
        userAction,
        setUserAction,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
