import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useReducer,useState } from "react";

export const AdminContext = createContext();

const initialState = {
  adminInfo: Cookies.get("adminInfo")
    ? JSON.parse(Cookies.get("adminInfo"))
    : null,
};

function reducer(state, action) {
  switch (action.type) {
    case "USER_LOGIN":
      return { ...state, adminInfo: action.payload };

    case "USER_LOGOUT":
      return {
        ...state,
        adminInfo: null,
      };

    default:
      return state;
  }
}

export const AdminProvider = ({ children }) => {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clientData, setClientData] = useState([] || {});
  // const [pageLoading, setPageLoading] = useState(true);
  const [currentCityID, setCurrentCityId] = useState("");
  const [fcmToken, setFcmToken] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  // const [changeCityId, setChangeCityId] = useState("");

  const updateToken = (callbackFunction) => {
    const cookieTimeOut = 0.5;
    axios({
      method: "POST",
      baseURL: BASE_URL_HULK,
      url: `${BASE_URL_HULK}token/refresh/`,
      data: { refresh: state.adminInfo?.refresh },
      headers: {
        "Content-Type": `multipart/form-data`,
      },
      withCredentials: false,
    })
      .then((res) => {
        // setPageLoading(false);
        if (res) {
          dispatch({
            type: "USER_LOGIN",
            payload: { ...state.adminInfo, access: res.data.access },
          });
          Cookies.set(
            "adminInfo",
            JSON.stringify({ ...state.adminInfo, access: res.data.access }),
            {
              expires: cookieTimeOut,
            }
          );
        }
        // window.location.reload();
        callbackFunction();
      })
      .catch((err) => {
        // setPageLoading(false);
        if (err.response.status === 401) {
          dispatch({ type: "USER_LOGOUT" });
          Cookies.remove("adminInfo");
        }
        console.log(err.message);
      });
  };

  // useEffect(() => {
  //   if (pageLoading) {
  //     updateToken();
  //   }
  // });
  const value = {
    state,
    dispatch,
    clientData,
    setClientData,
    currentCityID,
    setCurrentCityId,
    fcmToken,
    setFcmToken,
    notificationCount,
    setNotificationCount,
    updateToken,
    // setChangeCityId,
    // changeCityId,
  };
  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};
