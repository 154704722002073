import axios from "axios";
import Cookies from "js-cookie";
import React, { useState, createContext } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { ActionContext } from "./ActionContext";
import { AdminContext } from "./AdminContext";
import { SidebarContext } from "./SidebarContext";

export const CreateBookingContext = createContext();

export const CreateBookingProvider = ({ children }) => {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const {
    pickLat,
    pickLng,
    pickAddress,
    setPickAddress,
    setPickLat,
    setPickLng,
    setPickupAutocompleteData,
    // drop
    dropLat,
    dropLng,
    dropAddress,
    setDropAddress,
    setDropLat,
    setDropLng,
  } = useContext(SidebarContext);
  const { updateToken } = useContext(AdminContext);

  const { iscreateReturnModal, createReturnBooking } =
    useContext(ActionContext);

  // in create Booking
  const [carModel, setCarModel] = useState("");
  const [carName, setCarName] = useState("");

  // store data

  const [bookingData, setBookingData] = useState({
    city: "",
    booking_type: "",
    pickup_latitude: pickLat,
    pickup_longitude: pickLng,
    pickup_address: pickAddress,
    drop_latitude: dropLat,
    drop_longitude: dropLng,
    drop_address: dropAddress,
    service_centre: "",
    client_booking_id: "",
    vehicle_registration_number: "",
    // vehicle_registration_numbers: [],
    is_commercial: "",
    customer_name: "",
    customer_mobile: "",
    customer_alternate_mobile: "",
    customer_email: "",
    drop_contact_number: "",
    trip_category: "",
    instructions: "",
    send_track_sms_to: "both",
    customer_payment_mode: "",
    service_cost: "",
    invoice_number: "",
    checklist_url: "",
    num_drivers: "1",
    bike_type: "",
    car: "",
    pickup_time_type: "fixed",
    pickup_type: "pick_later",
    pickup_datetime: "",
  });

  // login toggle
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const closeLoginModal = () => setIsLoginModalOpen(false);
  const toggleLoginModal = () => setIsLoginModalOpen(!isLoginModalOpen);

  // otp toggle

  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const closeOTPModal = () => setIsOTPModalOpen(false);
  const toggleOTPModal = () => setIsOTPModalOpen(!isOTPModalOpen);

  // create booking conditional data
  const [createBookingData, setCreateBookingData] = useState({});
  // booking type input
  //  show drop booking type according to the city type
  const [bookingTypeCity, setBookingTypeCity] = useState([]);
  // booking type value

  // for payment mode show or not
  const [paymentMode, setPaymentMode] = useState("");

  //  for car_type show or not
  const [sendCarType, setSendCarType] = useState(false);

  // create book loading
  const [bookingLoading, setBookingLoading] = useState(false);

  useEffect(() => {
    const handleCreateBooking = () => {
      if (iscreateReturnModal) {
        let adminInfo;
        if (Cookies.get("adminInfo")) {
          adminInfo = JSON.parse(Cookies.get("adminInfo"));
        }
        axios({
          method: "GET",
          baseURL: BASE_URL_HULK,
          url: `${BASE_URL_HULK}booking-details/${createReturnBooking}/`,
          headers: {
            Authorization: `Bearer ${adminInfo.access}`,
            "Content-Type": `multipart/form-data`,
          },
          withCredentials: false,
        })
          .then(({ data }) => {
            let a = data.data;
            let b = a.car_type.split("-");
            setCarModel(b[0].trim());
            setCarName(b[1].trim());
            setPickAddress(a.pickup_address);
            setPickLat(a.pickup_latitude);
            setPickLng(a.pickup_longitude);
            setDropAddress(a.drop_address);
            setDropLat(a.drop_latitude);
            setDropLng(a.drop_longitude);
            setPickupAutocompleteData(true);
            setBookingData(a);
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
              updateToken(handleCreateBooking);
            }
          });
      }
    };
    handleCreateBooking();
    // eslint-disable-next-line
  }, [iscreateReturnModal]);

  return (
    <CreateBookingContext.Provider
      value={{
        // login model
        isLoginModalOpen,
        toggleLoginModal,
        closeLoginModal,

        // otp model
        isOTPModalOpen,
        toggleOTPModal,
        closeOTPModal,

        //create booking condition data
        createBookingData,
        setCreateBookingData,
        // booking type
        bookingTypeCity,
        setBookingTypeCity,

        // collecting all data
        bookingData,
        setBookingData,
        // payment mode in collecting all data
        paymentMode,
        setPaymentMode,

        //  car model in createBooking
        carName,
        carModel,
        setCarName,
        setCarModel,

        //
        sendCarType,
        setSendCarType,

        //
        bookingLoading,
        setBookingLoading,
      }}
    >
      {children}
    </CreateBookingContext.Provider>
  );
};
