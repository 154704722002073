import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { ToastContainer } from "react-toastify";
import { lazy } from "react";
import PushNotification from "./FirebaseNotification/PushNotification";
const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));
const PrivateRoute = lazy(() => import("./components/login/PrivateRoute"));
function App() {
  return (
    <>
      <PushNotification />
      <ToastContainer />
      <BrowserRouter>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          {/* <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} /> */}
          <PrivateRoute>
            <Route path="/" component={Layout} />
          </PrivateRoute>
          <Redirect exact from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
