import toast, { Toaster } from "react-hot-toast";
import { requestForToken, onMessageListener } from "./firebase";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { AdminContext } from "../context/AdminContext";
const PushNotification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const { setNotificationCount } = useContext(AdminContext);

  const notify = () =>
    toast.success(notification?.title + "\n" + notification?.body);
  //   function ToastDisplay() {
  //     return (
  //       <div>
  //         <p>
  //           <b>{}</b>
  //         </p>
  //         <p>{notification?.body}</p>
  //       </div>
  //     );
  //   }
  useEffect(() => {
    if (notification?.title) {
      notify();
      setNotificationCount((p) => p + 1);
    }
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    if (window.location.pathname !== "/login") {
      requestForToken();
    }
    // eslint-disable-next-line
  }, []);
  // console.log(countBool);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return <Toaster position="top-right" reverseOrder={false} />;
};

export default PushNotification;
